import * as React from 'react';
import axios from "axios";
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
// import highcharts3d from "highcharts/highcharts-3d";
// import cylinder from "highcharts/modules/cylinder";
import _ from 'lodash';
import HighChartsMore from 'highcharts/highcharts-more'
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsAccessibility from 'highcharts/modules/accessibility';
import HighchartsStreamgraph from 'highcharts/modules/streamgraph';
import CircularProgress from "@mui/material/CircularProgress";
import {API_ROOT} from './common/constants';

HighChartsMore(Highcharts);
HighchartsExporting(Highcharts);
HighchartsAccessibility(Highcharts);
HighchartsStreamgraph(Highcharts);
// highcharts3d(Highcharts);
// cylinder(Highcharts);

export default function HistoryDialog(props) {

  const categories = ['GC', 'GVW', 'Height', 'Manu', 'MH', 'OG', 'Weight']
  const [isLoading, setIsLoading] = React.useState(false);
  const [gd,sgd] = React.useState(false);
  const [displayCategory,setDisplayCategory] = React.useState('GC');
  const [displayYear,setDisplayYear] = React.useState(2022);
  const [distributionData, setDistributionData] = React.useState([
    ['General Construction',0], 
    // ['Gross Vehicle Weight',0], 
    // ['Over 16ft in height',0], 
    ['Manufacturing',0], 
    ['Manufacturing Housing',0], 
    ['Oil and Gas',0], 
    // ['Over 250Klb GVW',0]
  ]);
  const [historicalData, setHistoricalData] = React.useState([
    {
      name: 'General Construction',
      data: [0, 0, 0, 0]
    },
    {
      name: 'Gross Vehicle Weight',
      data: [0, 0, 0, 0]
    },
    {
      name: 'Over 16ft in height',
      data: [0, 0, 0, 0]
    },
    {
      name: 'Manufacturing',
      data: [0, 0, 0, 0]
    },
    {
      name: 'Manufacturing Housing',
      data: [0, 0, 0, 0]
    },
    {
      name: 'Oil and Gas',
      data: [0, 0, 0, 0]
    },
    {
      name: 'Over 250Klb GVW',
      data: [0, 0, 0, 0]
    }
  ]);
  const [distributionYear, setDistributionYear] = React.useState(props.year);

  // React.useEffect(() => {
  //   const interval = setInterval(() => 
  //     // setDisplayYear(displayYear<2022?displayYear+1:2015);
  //     setDisplayYear(displayYear+1)
  //   , 3000);
  //   console.log(displayYear);
  //   return () => clearInterval(interval);
  // }, []);

  const handleClose = () => {
    props.actionOpen(false);
  };



  const getLiveColOptions = {
    chart:{
      type:'cylinder',
      options3d: {
        enabled: true,
        alpha: 15,
        beta: 15,
        depth: 50,
        viewDistance: 25
      }
    },
    title: {
      text: 'Distribution of permits by category'
    },
    subtitle: {
      text: {'GC':'General Construction', 'GVW':'Gross Vehicle Weight', 'Height':'Over 16ft in height', 'Manu':'Manufacturing', 'MH':'Manufacturing Housing', 'Oil and Gas':'OG', 'Weight':'Over 250Klb GVW'}[displayCategory]
    },
    legend:{enabled:false},
    xAxis:{
      categories:[2015,2016,2017,2018,2019,2020,2021,2022],
    },
    yAxis:{
      title:{margin:20,text:'Weight in Tons'}
    },
    plotOptions:{
      series:{
        depth:25,
        // colorByPoint:true
      }
    },
    series:[{
      data:gd?getDataList(displayCategory,gd):[5,5,5,5,5,5,5,5],
      name: 'Weight in Tons',
    }],
    credits: {
      enabled: false,
    }
  }

  const getSPOptions = {

    chart: {
        polar: true,
        type: 'line'
    },

    title: {
        text: 'Distribution of permits by category',
        // x: 80
        margin: 50
    },
    subtitle: {
      text:displayYear,
    },
    legend:{enabled:false},
    pane: {
        size: '100%'
    },

    xAxis: {
        categories: ['General Construction', 'Gross Vehicle Weight', 'Over 16ft in height', 'Manufacturing', 'Manufacturing Housing', 'Oil and Gas', 'Over 250Klb GVW'],
        tickmarkPlacement: 'on',
        lineWidth: 0
    },

    yAxis: {
        gridLineInterpolation: 'polygon',
        lineWidth: 0,
        min: 0
    },

    tooltip: {
        shared: true,
        pointFormat: '<span style="color:{series.color}">{series.name}: <b>{point.y:,.0f}</b><br/>'
    },

    legend: {
        align: 'right',
        verticalAlign: 'middle',
        layout: 'vertical'
    },
    exporting:{
      buttons:{
        toggle:{
          text: 'Select year',
          className:'toggleButton',
          align:'right',
          height:30,
          x:0,
          y:25,
          theme: {
            'stroke-width': 0.5,
            stroke: '#000000',
            r: 2
          },
          menuItems : [2015,2016,2017,2018,2019,2020,2021,2022].map(y => ({
            text: y.toString(),
            onclick:() => { setDisplayYear(y);}
          }))
        }
      }
    },
    // series: [2015,2016,2017,2018,2019,2020,2021,2022].map(y => ({
    //   name: y.toString(),
    //   data: gd?['GC', 'GVW', 'Height', 'Manu', 'MH', 'OG', 'Weight'].map(c => gd[y][`${c}_Count`]):[1,2,3,4,5,6,7,8],
    //   pointPlacement: 'on'
    // })),
    series: [{
      name: 'Single Permit Counts',
      data: gd?['GC', 'GVW', 'Height', 'Manu', 'MH', 'OG', 'Weight'].map(c => gd[displayYear][`${c}_Count`]):[1,2,3,4,5,6,7,8],
      pointPlacement: 'on'
    }],
    responsive: {
        rules: [{
            condition: {
                maxWidth: 500
            },
            chartOptions: {
                legend: {
                    align: 'center',
                    verticalAlign: 'bottom',
                    layout: 'horizontal'
                },
                pane: {
                    size: '95%'
                }
            }
        }]
    },
    credits: {
      enabled: false,
    }
}

  const getPOptions = {

    chart: {
        polar: true
    },

    title: {
        text: 'Distribution of permits by category'
    },

    subtitle: {
        text: {'GC':'General Construction', 'GVW':'Gross Vehicle Weight', 'Height':'Over 16ft in height', 'Manu':'Manufacturing', 'MH':'Manufacturing Housing', 'Oil and Gas':'OG', 'Weight':'Over 250Klb GVW'}[displayCategory]
    },
    exporting:{
        buttons:{
          toggle:{
            text: 'Select year',
            className:'toggleButton',
            align:'right',
            height:30,
            x:0,
            y:25,
            theme: {
              'stroke-width': 0.5,
              stroke: '#000000',
              r: 2
            },
            menuItems : [2015,2016,2017,2018,2019,2020,2021,2022].map(y => ({
              text: y.toString(),
              onclick:() => { setDistributionYear(y);}
            }))
          }
        }
      },
    // pane: {
    //     startAngle: 0,
    //     endAngle: 360
    // },

    xAxis: {
        // tickInterval: 60,
        tickPositions:[0,60,120,180,240,300],
        tickmarkPlacement: 'on',
        min: 0,
        max: 360,
        // categories: ['General Construction', 'Gross Vehicle Weight', 'Over 16ft in height', 'Manufacturing', 'Manufacturing Housing', 'Oil and Gas', 'Over 250Klb GVW'],
        categories: ['General Construction', 'Gross Vehicle Weight', 'Over 16ft in height', 'Manufacturing', 'Manufacturing Housing', 'Oil and Gas'],
        // labels: {
        //     format: '{value}'
        // }
    },

    yAxis: {
        min: 0,
        visible: false
    },

    // plotOptions: {
    //     series: {
    //         pointStart: 0,
    //         pointInterval: 360/7
    //     },
    //     // column: {
    //     //     pointPadding: 0,
    //     //     groupPadding: 0
    //     // }
    // },

    series: [
    //   {
    //     type: 'column',
    //     name: 'Column',
    //     data: [8, 7, 6, 5, 4, 3, 2, 1],
    //     pointPlacement: 'between'
    // }, 
    // {
    //     type: 'line',
    //     name: 'Permit counts',
    //     data: [2, 3, 4, 5, 6, 7, 8]
    // }, 
    {
        type: 'area',
        name: 'Total weight',
        // pointPlacement: 'on',
        // data: [8, 2, 7, 3, 6, 4],
        data:[
          { y: 8, category: 'General Construction' },
          { y: 2, category: 'Gross Vehicle Weight' },
          { y: 7, category: 'Over 16ft in height' },
          { y: 3, category: 'Manufacturing' },
          { y: 6, category: 'Manufacturing Housing' },
          { y: 4, category: 'Oil and Gas' }
        ],
        pointStart: 0,
        pointInterval: 60
    }
  ],
    credits: {
      enabled: false,
    }
  };

  const getSGOptions = (type) => ({
    chart: {
      type,
      width: 800,
      height: 600
    },
    title: {
      text: 'Historical Counts Trend by Category',
      // align: 'left'
    },
    xAxis: {
      categories: ['2015','2016','2017', '2018', '2019', '2020','2021','2022'],
    },
    yAxis: {
      visible: false
    },
    series: historicalData,
    credits: {
      enabled: false,
    }
  });

  function getDataList(c, o) {
    const l = [];
    for (let y = 2015; y < 2023; y++) {
      l.push(o[y][`${c}_Count`]);
    }
    return l;
  }

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${API_ROOT}` + "/historical?ria_rte_id=" + props.segment.object.properties.RIA_RTE_ID + "&frm_dfo=" + props.segment.object.properties.FRM_DFO + "&to_dfo=" + props.segment.object.properties.TO_DFO
      );
      const o = response.data;
      sgd(o);
      setHistoricalData([
        {
          name: 'General Construction',
          // data: [o.gc_count_2015, o.gc_count_2016, o.gc_count_2017, o.gc_count_2018, o.gc_count_2019, o.gc_count_2020, o.gc_count_2021, o.gc_count_2022]
          data: getDataList('GC',o)
        },
        {
          name: 'Gross Vehicle Weight',
          // data: [o.gvw_count_2017, o.gvw_count_2018, o.gvw_count_2019, o.gvw_count_2020]
          data: getDataList('GVW',o)
        },
        {
          name: 'Over 16ft in height',
          // data: [o.height_count_2017, o.height_count_2018, o.height_count_2019, o.height_count_2020]
          data: getDataList('Height',o)
        },
        {
          name: 'Manufacturing',
          // data: [o.manu_count_2017, o.manu_count_2018, o.manu_count_2019, o.manu_count_2020]
          data: getDataList('Manu',o)
        },
        {
          name: 'Manufacturing Housing',
          // data: [o.mh_count_2017, o.mh_count_2018, o.mh_count_2019, o.mh_count_2020]
          data: getDataList('MH',o)
        },
        {
          name: 'Oil and Gas',
          // data: [o.og_count_2017, o.og_count_2018, o.og_count_2019, o.og_count_2020]
          data: getDataList('OG',o)
        },
        {
          name: 'Over 250Klb GVW',
          // data: [o.weight_count_2017, o.weight_count_2018, o.weight_count_2019, o.weight_count_2020]
          data: getDataList('Weight',o)
        }
      ]);
      setDistributionData(
        [['General Construction',o['gc_count_'+distributionYear]],
        // ['Gross Vehicle Weight',o['gvw_count_'+distributionYear]],
        // ['Over 16ft in height',o['height_count_'+distributionYear]],
        ['Manufacturing',o['manu_count_'+distributionYear]],
        ['Manufacturing Housing',o['mh_count_'+distributionYear]],
        ['Oil and Gas',o['og_count_'+distributionYear]],
        // ['Over 250Klb GVW',o['weight_count_'+distributionYear]]
      ]);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    if(gd){
      setDistributionData(
        [['General Construction',gd['gc_count_'+distributionYear]],
        // ['Gross Vehicle Weight',gd['gvw_count_'+distributionYear]],
        // ['Over 16ft in height',gd['height_count_'+distributionYear]],
        ['Manufacturing',gd['manu_count_'+distributionYear]],
        ['Manufacturing Housing',gd['mh_count_'+distributionYear]],
        ['Oil and Gas',gd['og_count_'+distributionYear]],
        // ['Over 250Klb GVW',gd['weight_count_'+distributionYear]]
      ]);
    }
  },[distributionYear]);

  React.useEffect(() => {
    fetchData()
  },[props.segment]);

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={'xl'}
        open={props.open}
        onClose={handleClose}
      >
        {/* <DialogTitle>Historical trend</DialogTitle> */}
        <DialogContent style={{ overflow: "hidden" }}>
          <DialogContentText>
            <p className="custom-block-title">
                <strong>Route ID:</strong> {props.segment.object.properties.RIA_RTE_ID}
              </p>
              <p className="custom-block-text">
              <strong>From/To DFO:</strong> {props.segment.object.properties.FRM_DFO} to{" "}
                {props.segment.object.properties.TO_DFO}
            </p>
          </DialogContentText>
          {!isLoading && (
            <Grid container>
              <Grid item xs={7}>
                <HighchartsReact highcharts={Highcharts} options={getSGOptions('streamgraph')} />
              </Grid>
              <Grid item xs={4}>
                <HighchartsReact highcharts={Highcharts} options={getSPOptions} />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        {isLoading && (
            <CircularProgress
              style={{
                marginLeft: "50%",
                // marginTop: "15%",
              }}
            />
          )}
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}