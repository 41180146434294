import {makeStyles} from "@mui/styles";
import { IconButton } from '@mui/material';
import IosShareIcon from '@mui/icons-material/IosShare';
import Tooltip from '@mui/material/Tooltip';

const exportStyle = {
    iconButtonStyle: {
    bgcolor:  '#ffff',
    'opacity':0.7,
    '&:hover': {
        bgcolor: '#ffffff',
        'opacity':1
    },
  }
}; 
const useStyles = makeStyles((theme) => ({
  container: {
    width: "60px",
    position: "absolute",
    top: "0%",
    right: "0%",
    paddingTop: "5px", 
  },
  infoIconStyle:{
    color:'rgb(204, 123, 41)',
  }
}));

export default function ExportMap(props) {
  const classes = useStyles();

  return (
     <div className={classes.container} >
        <Tooltip title="Export" placement="left">
            <IconButton sx={exportStyle.iconButtonStyle} onClick={props.exportfunction}>
                <IosShareIcon sx={{ fontSize: 40 }} className={classes.infoIconStyle}/>
            </IconButton>
        </Tooltip>
     </div>
  );
}
