import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import HomeIcon from '@mui/icons-material/Home';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import HelpIcon from '@mui/icons-material/Help';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import SearchIcon from '@mui/icons-material/Search';
import LocationCity from '@mui/icons-material/LocationCity';
import Tooltip from '@mui/material/Tooltip';
import {
  DISTRICTS,
  MPOS,
  COUNTIES,
  ROADHWY
} from "./common/constants";
import Select, {
  // components,
  createFilter,
} from "react-select";
// import { makeStyles } from "@material-ui/core/styles";
import {makeStyles} from "@mui/styles";

const drawerWidth = 600;
const sidebarStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    background: "#14375a",
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    padding: 5,
    overflowX: "auto",
    overflowY: "hidden",
  },
  divider: {
    // margin: "8px 0",
    marginBottom: "5px",
    height: 1.5,
  },
  sectionHeader: {
    color: "#FFFFFF",
    // fontStyle: "bold",
    paddingLeft: "0.5em",
    // fontSize: "1.15em",
  },
  text: {
    color: "#FFFFFF  !important",
    // fontStyle: "bold",
    paddingLeft: "0.75em",
    fontSize: "1.15em",
  },
  iconText: {
    color: "#FFFFFF  !important",
    // fontStyle: "bold",
    // paddingLeft: "0.25em",
    fontSize: "1.15em",
  },
}));

export function MainListItems(props) {

  const {openAction, drawerToggle, setSearchResult} = props;

  const classes = sidebarStyles();
  const quickReference = () => {
    const link = document.createElement("a");
    link.download =`Oversize Overweight Query Tool Basic Info.pdf`;
    link.href = './Oversize Overweight Query Tool Basic Info.pdf';
    link.click();
  };
  
  const grouped_option = [
    {
      label: "Districts",
      options: DISTRICTS.map((opt) => ({
        category:'district',
        label: opt.name,
        value: opt.geoRoute,
        statusGuide: opt.statusGuide,
        ...opt
      })),
    },
    {
      label: "Counties",
      options: COUNTIES.map((opt) => ({
        category:'county',
        label: opt.name,
        value: opt.geoRoute,
        statusGuide: opt.statusGuide,
        ...opt
      })),
    },
    {
      label: "MPOs",
      options: MPOS.map((opt) => ({
        category:'mpo',
        label: opt.name,
        value: opt.geoRoute,
        statusGuide: opt.statusGuide,
        ...opt
      })),
    },
    {
      label: "Roads",
      options: ROADHWY.map((opt) => ({
        category:'routeid',
        label:opt,
        id: opt,
        geoRoute: '/roads/findByRouteId?route_id='+opt,
        statusGuide: ['Road ID',opt],

      }))
    }
  ];
  
  const customStyles = {
    control: (base, state) => ({
      ...base,
      color: "white",
      // match with the menu
      borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      // boxShadow: state.isFocused ? null : null,
      // "&:hover": {
      //   // Overwrittes the different states of border
      //   borderColor: state.isFocused ? "red" : "blue",
      // },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
      background: "#1e1e1e",
      color: "white",
    }),
    menuList: (base, state) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
      width:'max-content',
      background: "#1e1e1e",
      color: "white",
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? // ? "hsla(291, 64%, 42%, 0.5)"
          "#ffc838"
        : isSelected
        ? // ? "hsla(291, 64%, 42%, 1)"
          "#3a8848"
        : undefined,
      zIndex: 1,
    }),
  };

  var handleSelect = (e) => {
    setSearchResult(e);
  };

  return (
    <div>
      <Tooltip title="Help" placement="right">
      <ListItem button onClick={quickReference}>
      {/* <ListItem button href='/Oversize Overweight Query Tool Basic Info.pdf'> */}
        <ListItemIcon className={classes.text}>
          <HelpIcon />
        </ListItemIcon>
        <ListItemText primary="Quick Reference" className={classes.iconText}/>
      </ListItem>
      </Tooltip>
      <Tooltip title="Home" placement="right">
      <ListItem button onClick={() => openAction("home")}>
        <ListItemIcon className={classes.text}>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary="Home" className={classes.iconText}/>
      </ListItem>
      </Tooltip>
      <Tooltip title="Counties" placement="right">
      <ListItem button onClick={() => openAction("county")}>
        <ListItemIcon className={classes.text}>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Counties" className={classes.iconText}/>
      </ListItem>
      </Tooltip>
      <Tooltip title="MPOs" placement="right">
      <ListItem button onClick={() => openAction("mpo")}>
        <ListItemIcon className={classes.text}>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="MPOs" className={classes.iconText}/>
      </ListItem>
      </Tooltip>
      <Tooltip title="Districts" placement="right">
      <ListItem button onClick={() => openAction("district")}>
        <ListItemIcon className={classes.text}>
          <LocationCity />
        </ListItemIcon>
        <ListItemText primary="Districts" className={classes.iconText}/>
      </ListItem>
      </Tooltip>
      <ListItem
        key={"sidebar-group-search"}
      >
        <ListItemIcon button className={classes.text} onClick={() => drawerToggle()}>
          <SearchIcon />
        </ListItemIcon>
        {/* <ListItemText primary="Search" className={classes.iconText} /> */}
      
      <Select
        options={grouped_option}
        isClearable={true}
        classNamePrefix="select"
        onChange={handleSelect}
        placeholder={<div>Search</div>}
        filterOption={createFilter({ ignoreAccents: false })}
        styles={customStyles}
      />
      </ListItem>
      {/* <Tooltip title="Some charts" placement="right">
      <ListItem button onClick={() => props.openAction("cty")}>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary="Some charts" />
      </ListItem>
      </Tooltip>
      <Tooltip title="Other category" placement="right">
      <ListItem button onClick={() => props.openAction("cty")}>
        <ListItemIcon>
          <LayersIcon />
        </ListItemIcon>
        <ListItemText primary="Other category" />
      </ListItem>
      </Tooltip> */}
    </div>
  );
}

export function SecondaryListItems() {
  return (
    <div>
      <ListSubheader inset>Reports</ListSubheader>
      <Tooltip title="Get report 1" placement="right">
      <ListItem button>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Get report 1" />
      </ListItem>
      </Tooltip>
      <Tooltip title="See report 3" placement="right">
      <ListItem button>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="See report 3" />
      </ListItem>
      </Tooltip>
      <Tooltip title="Report 4" placement="right">
      <ListItem button>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Report 4" />
      </ListItem>
      </Tooltip>
    </div>
  );
}