import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useMemo,
  createRef
  //    useCallback
} from "react";
import axios from "axios";
import { useTheme } from '@mui/material/styles';
import {MAPBOX_TOKEN, MAPBOX_ATTRIBUTION, baseLayers,} from './common/constants'
import { RhinoQueryContext } from "./RhinoQueryContext";
import DeckGL from '@deck.gl/react';
import bbox from "@turf/bbox";
import buffer from "@turf/buffer";
import { LineLayer, GeoJsonLayer, IconLayer } from "@deck.gl/layers";
import {
  StaticMap,
  MapContext,
  NavigationControl,
  Popup,
  ScaleControl,
  WebMercatorViewport,
} from "react-map-gl";
import 'mapbox-gl/dist/mapbox-gl.css';
import CircularProgress from "@mui/material/CircularProgress";
import {API_ROOT,COLOR_RHINO} from './common/constants';
import {scaleLinear} from 'd3-scale';
// import * as d3 from "d3";
import mapboxgl from 'mapbox-gl';
import styles from "./App.css";
import MapTabs from './mapTabs';
import ExportMap from "./ExportMap";
import ColorLegend from "./svgLegend";
import HistoryDialog from "./HistoryDialog";
import FileSaver from "file-saver";
import LayerControls from  './LayerControls';
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

export default function MapComponent(props) {
  
  const [isLoading, setIsLoading] = React.useState(false);
  const [query, dispatch] = React.useContext(RhinoQueryContext);
  const theme = useTheme();

  const defaultUserSelection = {
    from_ria_rte_id: "",
    from_frm_dfo: Number.Nan,
    from_to_dfo: Number.NaN,
    to_frm_dfo: Number.NaN,
    to_to_dfo: Number.NaN,
  };
  const userSelection = React.useRef(defaultUserSelection);
  const [rhino, setRhino] = React.useState({
    type: "FeatureCollection",
    features: [],
  });
  const [rhinoPolygon, setRhinoPolygon] = useState(null);
  const [rhinoLineColorsWeight, setRhinoLineColorsWeight] = React.useState(null);
  const [DeckCanvas, setDeckCanvas] = React.useState(null);
  const [wRange,setWrange] = React.useState(null);
  let refDeckgl = createRef();
  let refMap = createRef();

  const [selectedPoint, setSelectedPoint] = React.useState(null);
  const [activePopup, setActivePopup] = React.useState(false);
  const [activeHistory, setActiveHistory] = React.useState(false);

  const [info, setInfo] = useState(
    <div className="custom-block-hover">
    <h4>Hover over a road</h4>
    </div>
  );
  
  const [baseMap, setBaseMap] = useState("mapbox://styles/mapbox/streets-v11");
  const [showRoads, setShowRoads] = useState(true);

  const [zoomLevel, setZoomLevel] = useState({
    zoomLevel: 4.85,
    zoomScale: 1,
  });

  const stylesHoverBox = {
    h6Header: {
      textAlign: "left",
    },
    leftText: {
      textAlign: "left",
      marginBottom: "0",
      paddingBottom: "0",
    },
    leftBullet: {
      textAlign: "left",
      marginLeft: "-25px",
    },
    rightBullet: {
      textAlign: "left",
      marginLeft: "20px",
    },
    leftHeader: {
      textAlign: "left",
      marginLeft: "-35px",
    },
    rightHeader: {
      textAlign: "left",
      marginLeft: "10px",
    },
  };

  const NAV_CONTROL_STYLE = {
    // position: "absolute",
    // top: 5,
    // left: 5,
    position: "absolute",
    // position: "relative",
    top: "2%",
    left: "10px",
    width: 30,
  };
  const ScaleControl_STYLE = {
    // position: "absolute",
    // top: 350,
    // left: 5,
    position: "absolute",
    // position: "relative",
    bottom: "7%",
    left: "10px",
  };
  const layers_CONTROL_STYLE = {
    // position: "absolute",
    // top: 90,
    // left: 5,
    position: "absolute",
    paddingLeft: "40px",
  };

  const [fromPoints, setFromPoints] = useState([]);

  useMemo(() => {
    if (rhino && props.mapType === "deckGL-polygons") {
      setRhinoPolygon(buffer(rhino, 0.05, { units: "miles" }));
    }
  }, [rhino, props.mapType]);

  const [roadLayerVisibility, setRoadLayerVisibility] = useState({
    lines: true,
    polygons: false,
  });
  useMemo(() => {
      if (props.mapType === "deckGL-lines"){
        setRoadLayerVisibility({
          lines: true,
          polygons: false,
        });
      }
      else{
        setRoadLayerVisibility({
          lines: false,
          polygons: true,
        });
      }
  }, [props.mapType]);

  const tx100Info = (rprops) => {
    //  console.log('count:',rprops[`${props.category}_count`],'||', rprops[`${props.category}_count`] ? rprops[`${props.category}_count`] : 'N/A');
    // console.log('weight:',rprops[`${props.category}_tons`],'||', rprops[`${props.category}_tons`] ? rprops[`${props.category}_tons`] : 'N/A');
    return (
      <div className="custom-block-hover" style={{ padding: "1px" }}>
        <p>
          <strong>Route ID:</strong> {rprops.RIA_RTE_ID}
        </p>
        <p>
        <strong>From/To DFO:</strong> {rprops.FRM_DFO} to{" "} {rprops.TO_DFO}
        </p>
        <p>
        <strong>Count:</strong> {rprops[`${props.selectedYear}_Count`] ? parseInt(rprops[`${props.selectedYear}_Count`]).toLocaleString('en-US') : 'N/A'}
        </p>
        <p>
        <strong>Weight:</strong> {rprops[`${props.selectedYear}_Tons`] ? parseFloat(rprops[`${props.selectedYear}_Tons`]).toLocaleString('en-US',{maximumFractionDigits: 2}) + ' tons': 'N/A'}
        </p>
      </div>
    );
  };

  const viewport = {
    width: "100%",
    height: "100%",
  }; 

  const [fromToCleasrButtonDisabled, setFromToClearButtonDisabled] = useState({
    fromButton: false,
    toButton: true,
    clearButton: true,
  });

  function handleFrom() {
    let featureProps = selectedPoint.object.properties;
    // console.log("selectedPoint-check", selectedPoint);

    resetUserSelection(userSelection.current.from_ria_rte_id);
    userSelection.current = {
      from_ria_rte_id: featureProps.ria_rte_id,
      from_frm_dfo: featureProps.frm_dfo,
      from_to_dfo: featureProps.to_dfo,
    };

    // highlightUserSelection("from");

    setFromToClearButtonDisabled({
      fromButton: true,
      toButton: false,
      clearButton: false,
    });

    // let verticeIndex = Math.floor(
    //   selectedPoint.object.geometry.coordinates[0].length / 2
    // );

    // setFromPoints([
    //   {
    //     type: "From",
    //     // coordinates: selectedPoint.coordinate,
    //     coordinates: selectedPoint.object.geometry.coordinates[0][verticeIndex],
    //   },
    // ]);
    setFromPoints([
      {
        type: "From",
        // coordinates: selectedPoint.coordinate,
        coordinates: selectedPoint.coordinate,
      },
    ]);

    // console.log(
    //   "rhinoTest",
    //   rhino.features.filter(
    //     (f) => f.properties.ria_rte_id === featureProps.ria_rte_id
    //   )
    // );
    setActivePopup(false);
    // setSelectedPoint(null);
  }

  function handleTo() {
    let rprops = selectedPoint.object.properties;

    let newSelection = { ...userSelection.current };
    newSelection.to_frm_dfo = rprops.frm_dfo;
    newSelection.to_to_dfo = rprops.to_dfo;
    userSelection.current = newSelection;
    const fromDfo = Math.min(
      newSelection.from_frm_dfo,
      newSelection.to_frm_dfo
    );
    const toDfo = Math.max(newSelection.from_to_dfo, newSelection.to_to_dfo);
    dispatch({
      type: "setUserSelection",
      selection: {
        ria_rte_id: newSelection.from_ria_rte_id,
        frm_dfo: fromDfo,
        to_dfo: toDfo,
      },
    });
    // highlightUserSelection();

    resetUserSelection(userSelection.current.from_ria_rte_id);

    setFromToClearButtonDisabled({
      fromButton: false,
      toButton: true,
      clearButton: true,
    });
    setActivePopup(false);
    setSelectedPoint(null);
    // console.log("userSelection.current", userSelection.current);

    setFromPoints([]);
  }

  function handleClearSelection() {
    resetUserSelection(userSelection.current.from_ria_rte_id);
    setFromToClearButtonDisabled({
      fromButton: false,
      toButton: true,
      clearButton: true,
    });
    setFromPoints([]);
  }

  function resetUserSelection(ria_rte_id) {
    
    userSelection.current = defaultUserSelection;

    rhino.features.forEach((f) => {
      f.color = f.color_base;
      f.weight = f.weight_base;
    });
  }

  function handleAddSegment() {
    setActivePopup(false);
    setSelectedPoint(null);
    
    let rprops = selectedPoint.object.properties;
    
    resetUserSelection(userSelection.current.ria_rte_id);

    props.actionTable(props.inTable.concat(rprops))

    dispatch({
      type: "setUserSelection",
      selection: {
        ria_rte_id: rprops.ria_rte_id,
        frm_dfo: rprops.frm_dfo,
        to_dfo: rprops.to_dfo,
      },
    });
    
  }

  const showHistory = () => {
    if(activeHistory){
      return (<HistoryDialog year={props.selectedYear} segment={selectedPoint} actionOpen={setActiveHistory} open={activeHistory} />);
    }
  };

  const renderPopup = (obj) => {
    if (selectedPoint !== null && selectedPoint !== undefined && activePopup) {
      return (
        <Popup
          key="popup"
          longitude={selectedPoint.coordinate[0]}
          latitude={selectedPoint.coordinate[1]}
          closeButton={true}
          onClose={() => setSelectedPoint(null)}
        >
          <div className="custom-block">
            <p className="custom-block-layer">
            </p>
            <p className="custom-block-title">
              <strong>Route ID:</strong> {selectedPoint.object.properties.RIA_RTE_ID}
            </p>
            <p className="custom-block-text">
            <strong>From/To DFO:</strong> {selectedPoint.object.properties.FRM_DFO} to{" "}
              {selectedPoint.object.properties.TO_DFO}
            </p>
            <div>
              <p className="from-to-links">
                <button
                  className="custom-block-button"
                  onClick={() => setActiveHistory(true)}
                >
                  More details
                </button>
              </p>
            </div>
          </div>
        </Popup>
      );
    }
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      
      const response = await axios.get(
        `${API_ROOT}` + "/geometry/" + props.category + "/" +  props.selectedYear + "/" + props.region + "/" + props.selectedValue.id
      );

      let fc = response.data.features;
      let fc_colors_weight = [];
      let colorTemp = COLOR_RHINO.normal
        .match(/[0-9a-f]{2}/g)
        .map((x) => parseInt(x, 16));
      let colorTempE = COLOR_RHINO.empty
        .match(/[0-9a-f]{2}/g)
        .map((x) => parseInt(x, 16));
      let minWeight = Math.min(...fc.map(obj=>obj.properties[props.selectedYear + "_Tons"]));
      let maxWeight = Math.max(...fc.map(obj=>obj.properties[props.selectedYear + "_Tons"]));
      let colorScale = scaleLinear([minWeight,0.5*(minWeight+maxWeight), maxWeight],["green","yellow","red"]);
      setWrange([minWeight,maxWeight]);
      fc.forEach((obj) => {obj.properties.color=colorScale(obj.properties[props.selectedYear + "_Tons"])});
      
      fc.map((obj) => {
        fc_colors_weight.push({
          // rte_id: obj.properties.RIA_RTE_ID,
          // top100id: "",
          // ogc_fid: obj.properties.ogc_fid,
          // frm_dfo: obj.properties.frm_dfo,
          // to_dfo: obj.properties.to_dfo,
          // color: colorTemp,
          // color_base: obj.properties.total_count? colorTemp: colorTempE,
          weight: obj.properties[props.category+"_Count"]? 5: 0,
          // weight_base: 1,
          // height:500
          // height:obj.properties[`${props.category}_count`]
          // height:obj.properties[`${props.category}_count`]?obj.properties[`${props.category}_count`]:1
          // height:1000+700*((obj.properties[`${props.category}_count`]?obj.properties[`${props.category}_count`]:minCount)-minCount)/(maxCount-minCount)
        });
      });
      props.actionTable([]);

      const responseData = await axios.get(
        `${API_ROOT}` + "/data/" +  props.selectedYear + "/" + props.region + "/" + props.selectedValue.id
      );

      props.setData(responseData.data);
      
      // props.setData(fc.map((obj) =>{return {...obj.properties}}).filter((value, index, self) =>
      //   index === self.findIndex((t) => (
      //     t.ria_rte_id === value.ria_rte_id && t.frm_dfo === value.frm_dfo && t.to_dfo === value.to_dfo
      //   ))
      // ));

      setRhino({
        type: "FeatureCollection",
        features: fc.filter((item) => item.properties[`${props.selectedYear}_Count`]),
      });
      setRhinoLineColorsWeight(fc_colors_weight);
      
      setIsLoading(false);

      const mapBounds_bbox = bbox(
        {
          type: "FeatureCollection",
          features: fc,
        }
        //{ rhino }
      );
      props.setMapBounds(props.getBounds(mapBounds_bbox));
    } catch (e) {
      console.log(e);
    }
  };

  const handleMapExport = () => {
    const fileName = "OSOW_map.png";
    
    if (!refDeckgl.current) {return;}
    
    const mapGL = refMap.current.getMap();
    const deckelem = refDeckgl.current.deck;
    deckelem.redraw(true);
    
    const mapboxCanvas = mapGL.getCanvas();

    let merge = document.createElement("canvas");
    merge.width = mapboxCanvas.width;
    merge.height = mapboxCanvas.height;

    var context = merge.getContext("2d");
    
    context.globalAlpha = 1.0;
    context.drawImage(mapboxCanvas, 0, 0);

    context.globalAlpha = 1.0;
    // context.drawImage(deckelem.canvas, 0, 0);
    context.drawImage(DeckCanvas, 0, 0);
    
    merge.toBlob((blob) => {
      FileSaver.saveAs(blob, fileName);
    });
  };

  React.useEffect(() => {
    if(props.region === "home"){
      setRhino({
        type: "FeatureCollection",
        features: [],
      });
      props.setData([]);
      props.setLocatedRoads([]);
      setActivePopup(false);
      props.actionTable([]);
      setWrange(null);
      props.setMapBounds(props.initialMapView)
    } else {
      fetchData();

    }
  }, [props.selectedValue, props.selectedYear, props.category]);

  React.useEffect(() => {
    if(props.locatedRoads.length > 0){
      const mapbbox = bbox({type: "FeatureCollection",features:rhino.features.filter(e => props.locatedRoads.some(f => f.RIA_RTE_ID === e.properties.RIA_RTE_ID && Math.abs(f.FRM_DFO-e.properties.FRM_DFO)<0.01 && Math.abs(f.TO_DFO-e.properties.TO_DFO)<0.01))});
      const bboxbounds = props.getBounds(mapbbox);
      props.setMapBounds(bboxbounds);
    }
  }, [props.locatedRoads]);

  let currentContentHeight = 1;
  try {
    currentContentHeight = document.querySelector(`#map`).offsetHeight;
  } catch {
    currentContentHeight = 1;
  }

  return (
    <React.Fragment>
      <DeckGL
        initialViewState={{
          longitude: props.mapBounds.longitude,
          latitude: props.mapBounds.latitude,
          zoom: props.mapBounds.zoom,
          pitch: 0,
          bearing: 0,
        }}
        ref={refDeckgl}
        controller={true}
        ContextProvider={MapContext.Provider}
        layers={[]}
        // getCursor={() => 'url(/home/azureuser-ss/osow-ui/src/TTI-Color-reverse.png)'}
        // getCursor={() => "inherit"}
        onAfterRender={(e) => { 
          setDeckCanvas(e.gl.canvas);
        }}
        onViewStateChange={({ viewState }) => {
          // console.log("viewState-change", viewState);
          // setZoomLevel(viewState.zoom);

          let zoomScaleTemp = 1;
          if (viewState.zoom > 4.85)
            zoomScaleTemp = 4.85 / (viewState.zoom * viewState.zoom);
          setZoomLevel({
            zoomLevel: viewState.zoom,
            zoomScale: zoomScaleTemp,
            // pitch: viewState.pitch,
          });

          // buildGeoJsonLayer();
        }}
        >
        <MapTabs  hoverInfo={info}/>
        <ExportMap  exportfunction={handleMapExport}/>
        <NavigationControl style={NAV_CONTROL_STYLE} />
        <ScaleControl style={ScaleControl_STYLE} />
        {wRange && <ColorLegend range={wRange} />}
        <div style={layers_CONTROL_STYLE}>
          <LayerControls
            baseMap={baseMap}
            setBaseMap={setBaseMap}
            showRoads={showRoads}
            setShowRoads={setShowRoads}
          />
        </div>
        <StaticMap ref={refMap} mapStyle={baseMap} mapboxApiAccessToken={MAPBOX_TOKEN} preserveDrawingBuffer={true} />
        {activeHistory && showHistory()}
        {activePopup && renderPopup()}
        {isLoading && (
          <CircularProgress
            style={{
              marginLeft: "50%",
              marginTop: "15%",
            }}
          />
        )}
        {roadLayerVisibility.lines && (<GeoJsonLayer
          id="roads"
          data={rhino}
          visible={true}
          pickable={true}
          pickingRadius={5}
          stroked={false}
          filled={false}
          extruded={false}
          lineWidthMinPixels={4}  
          // getFillColor={[160, 160, 180, 200]}
          getLineColor={(d, index) => {
            // return rhinoLineColorsWeight[index.index].color;
            return d.properties.color.replace(/[^\d,]/g, '').split(',').map(Number);
          }}
          getLineWidth={(d, index) => {
            let colorWeightTemp = rhinoLineColorsWeight[index.index];
            return colorWeightTemp.weight;
          }}
          onClick={(d, event) => {
            setActivePopup(true);
            setSelectedPoint(d);
          }}
          onHover={(d, event) => {
            if (d.object !== undefined)
             setInfo(tx100Info(d.object.properties));
          }}
          autoHighlight={true}
          highlightColor={COLOR_RHINO.selected
            .match(/[0-9a-f]{2}/g)
            .map((x) => parseInt(x, 16))}
        />
        )}

{roadLayerVisibility.polygons && (
          <GeoJsonLayer
            id="roads"
            // data={rhino}
            data={rhinoPolygon}
            visible={roadLayerVisibility.polygons}
            // filled: true,
            opacity= {0.1}
            pickable={true}
            pickingRadius={5}
            stroked={false}
            filled={true}
            extruded={true}
            pointType={"circle"}
            lineWidthScale={20}
            lineWidthMinPixels={0.5}
            getPointRadius={100}
            // getElevation={50}
            getElevation={(d, index) => {
              // console.log('rhinoLineColorsWeight[d.index].height: ',rhinoLineColorsWeight[d.index].height);
              // console.log('count: ',d.object.properties[`${props.category}_count`]);
              // console.log('fromColor: ',rhinoLineColorsWeight[index.index]);
              // console.log('fromd: ',d);
              // console.log('fromd: ',d.properties[`${props.category}_count`]);
              // return rhinoLineColorsWeight[index.index].height;
              return d.properties[`${props.selectedYear}_Count`];
              // return d.object.properties[`${props.category}_count`];
            }}
            getFillColor={(d, index) => {
              return d.properties.color.replace(/[^\d,]/g, '').split(',').map(Number);
              // return rhinoLineColorsWeight[index.index].color
            }}
            getLineWidth={(d, index) => {
              let colorWeightTemp = rhinoLineColorsWeight[index.index];
              return colorWeightTemp.weight * 5;
            }}
            // getElevation={(d, index) => {
            //   let zoomScale = zoomLevel.zoomScale;
            //   // let elevationTemp = rhinoLineColorsWeight[index.index].elevScale;
            //   let elevationTemp = 1;

            //   return (
            //     20000 * (currentContentHeight / 421) * zoomScale * elevationTemp
            //   );
            // }}
            onClick={(d, event) => {
              setActivePopup(true);
              setSelectedPoint(d);
            }}
            onHover={(d, event) => {
              if (d.object !== undefined){
                // console.log('rhinoLineColorsWeight[d.index].height: ',rhinoLineColorsWeight[d.index].height);
                // console.log('count: ',d.object.properties[`${props.category}_count`])
                setInfo(tx100Info(d.object.properties));
              }
            }}
            autoHighlight={true}
            highlightColor={COLOR_RHINO.selected
              .match(/[0-9a-f]{2}/g)
              .map((x) => parseInt(x, 16))}
          />
        )}
      </DeckGL>
    </React.Fragment>
  );
}