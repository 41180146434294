import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import EmojiTransportation from '@mui/icons-material/EmojiTransportation';
import { ListItemIcon } from '@mui/material';
import {COUNTIES,MPOS,DISTRICTS,ROADHWY} from './common/constants'

export default function SelectionDialog(props) {

    const { onClose, selectedValue, open, region } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    const reg = {"home":[],"county":COUNTIES,"mpo":MPOS,"district":DISTRICTS,"routeid":[]}[region]

    return (
        <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
            <DialogTitle>Select Layers</DialogTitle>
            <List sx={{ pt: 0 }}>
                {reg.map((rgn) => (
                <ListItem button onClick={() => handleListItemClick(rgn)} key={rgn.id}>
                    <ListItemIcon>
                        <EmojiTransportation />
                    </ListItemIcon>
                    <ListItemText primary={rgn.name} />
                </ListItem>
                ))}
            </List>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}
  
 