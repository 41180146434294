import React, { useState, useContext } from "react";

import {makeStyles} from "@mui/styles";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import InfoIcon from "@mui/icons-material/Info";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "300px",
    position: "absolute",
    bottom: "0%",
    right: "0%",
  },
  tabHeader: {
    // borderBottomStyle: "solid",
    borderWidth: '2.1px',
    // borderColor: 'rgb(204, 123, 41)',
    marginBottom: "5px",
    display: "block",
  },
  infoIconStyle:{
    color:'rgb(204, 123, 41)',
    paddingTop: "0px", 
    marginTop: "0px"
  }
}));

export default function MapTabs(props) {
  const classes = useStyles();

  return (
     <div className={classes.container} style={{ fontSize: "12.5px" }}>
       <Grid item xs={12}>
                <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <InfoIcon className={classes.infoIconStyle}/>&nbsp;
          <Typography className={classes.tabHeader}
                  style={{ paddingTop: "0px", marginTop: "0px",}}><strong>Quick Info</strong> </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div children={props.hoverInfo} />
          </Typography>
        </AccordionDetails>
      </Accordion>
              </Grid>
     </div>
  );
}
