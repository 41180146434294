import React from 'react';
import MaterialReactTable, {MRT_FullScreenToggleButton, MRT_ShowHideColumnsButton, MRT_ToggleFiltersButton, MRT_ToggleGlobalFilterButton} from 'material-react-table';
import { Box, Button, IconButton } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PrintIcon from '@mui/icons-material/Print';
import MapIcon from '@mui/icons-material/Map';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import Typography from "@mui/material/Typography";
import Menu from '@mui/material/Menu';
import bbox from "@turf/bbox";
import MenuItem from '@mui/material/MenuItem';
import { propsToClassKey } from '@mui/styles';
import reactSelect from 'react-select';

const columns = [
    {
        accessorKey: 'RIA_RTE_ID',
        header: 'Name',
    },
    {
        accessorKey: 'FRM_DFO',
        header: 'From DFO',
    },
    {
        accessorKey: 'TO_DFO',
        header: 'To DFO',
    },
    {
        accessorKey: 'GC_Count',
        header: 'Gen Construction Count',
        Cell: ({cell}) => cell.getValue().toLocaleString('en-US'),
        // muiTableBodyCellProps: {
        //     align: 'center',
        //   }
    },
    {
        accessorKey: 'GC_Tons',
        header: 'Gen Construction Weight',
        Cell: ({cell}) => cell.getValue().toLocaleString('en-US',{maximumFractionDigits: 2}),
        // muiTableBodyCellProps: {
        //     align: 'center',
        //   }
    },
    {
        accessorKey: 'GVW_Count',
        header: 'Gross Vehicle Count',
        Cell: ({cell}) => cell.getValue().toLocaleString('en-US'),
        // muiTableBodyCellProps: {
        //     align: 'center',
        //   }
    },
    {
        accessorKey: 'GVW_Tons',
        header: 'Gross Vehicle Weight',
        Cell: ({cell}) => cell.getValue().toLocaleString('en-US',{maximumFractionDigits: 2}),
        // muiTableBodyCellProps: {
        //     align: 'center',
        //   }
    },
    {
        accessorKey: 'Height_Count',
        header: 'Over 16ft Count',
        Cell: ({cell}) => cell.getValue().toLocaleString('en-US'),
        // muiTableBodyCellProps: {
        //     align: 'center',
        //   }
    },
    {
        accessorKey: 'Height_Tons',
        header: 'Over 16ft Weight',
        Cell: ({cell}) => cell.getValue().toLocaleString('en-US',{maximumFractionDigits: 2}),
        // muiTableBodyCellProps: {
        //     align: 'center',
        //   }
    },
    {
        accessorKey: 'Manu_Count',
        header: 'Manufacturing Count',
        Cell: ({cell}) => cell.getValue().toLocaleString('en-US'),
        // muiTableBodyCellProps: {
        //     align: 'center',
        //   }
    },
    {
        accessorKey: 'Manu_Tons',
        header: 'Manufacturing Weight',
        Cell: ({cell}) => cell.getValue().toLocaleString('en-US',{maximumFractionDigits: 2}),
        // muiTableBodyCellProps: {
        //     align: 'center',
        //   }
    },
    {
        accessorKey: 'MH_Count',
        header: 'Manufacturing Housing Count',
        Cell: ({cell}) => cell.getValue().toLocaleString('en-US'),
        // muiTableBodyCellProps: {
        //     align: 'center',
        //   }
    },
    {
        accessorKey: 'MH_Tons',
        header: 'Manufacturing Housing Weight',
        Cell: ({cell}) => cell.getValue().toLocaleString('en-US',{maximumFractionDigits: 2}),
        // muiTableBodyCellProps: {
        //     align: 'center',
        //   }
    },
    {
        accessorKey: 'OG_Count',
        header: 'Oil and Gas Count',
        Cell: ({cell}) => cell.getValue().toLocaleString('en-US'),
        // muiTableBodyCellProps: {
        //     align: 'center',
        //   }
    },
    {
        accessorKey: 'OG_Tons',
        header: 'Oil and Gas Weight',
        Cell: ({cell}) => cell.getValue().toLocaleString('en-US',{maximumFractionDigits: 2}),
        // muiTableBodyCellProps: {
        //     align: 'center',
        //   }
    },
    {
        accessorKey: 'Weight_Count',
        header: 'Over 250Klb Count',
        Cell: ({cell}) => cell.getValue().toLocaleString('en-US'),
        // muiTableBodyCellProps: {
        //     align: 'center',
        //   }
    },
    {
        accessorKey: 'Weight_Tons',
        header: 'Over 250Klb Weight',
        Cell: ({cell}) => cell.getValue().toLocaleString('en-US',{maximumFractionDigits: 2}),
        // muiTableBodyCellProps: {
        //     align: 'center',
        //   }
    },
];

const csvOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  filename:'OSOW_data',
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: false,
  headers: columns.map((c) => c.header),
};

const csvExporter = new ExportToCsv(csvOptions);

const emptyObj = Object.fromEntries(columns.map(k=>[k.accessorKey,0]));

const AllRoadsTable = (props) => {
    
    const handleExportRows = (rows) => {
        csvExporter.generateCsv(rows.map(row=>Object.assign({},emptyObj,row.original,{'color':''})));
        setAnchorEl(null);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [colDefaultVis,setColDefaultVis] = React.useState({
        'GC':props.category=='GC',
        'GVW':props.category=='GVW',
        'Height':props.category=='Height',
        'Manu':props.category=='Manu',
        'MH':props.category=='MH',
        'OG':props.category=='OG',
        'Weight':props.category=='Weight'
    });
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleExportData = () => {
        csvExporter.generateCsv(props.data.map(v=>Object.assign({},emptyObj,v,{'color':''})));
        setAnchorEl(null);
    };

    React.useEffect(() => 
        setColDefaultVis({
            'GC':props.category=='GC',
            'GVW':props.category=='GVW',
            'Height':props.category=='Height',
            'Manu':props.category=='Manu',
            'MH':props.category=='MH',
            'OG':props.category=='OG',
            'Weight':props.category=='Weight'
        })
      , [props.category]);

  return (
    <React.Fragment>
        {props.data.length === 0 && (
            props.partialView? 
                <Typography align='center' variant="h5">Please select a segment or custom corridor using the map above.</Typography>: 
                <Typography align='center' variant="h5">Please select a region from the menu on the left panel.</Typography>
        )}
        {props.data.length > 0 && (
            <MaterialReactTable
                columns={columns}
                data={props.data}
                // muiTableBodyCellProps={{
                //     sx: {
                //       borderRight: '2px solid #e0e0e0', //add a border between columns
                //     },
                //   }}
                initialState={{
                    density:'compact',
                    columnVisibility: { 
                        GC_Count: colDefaultVis["GC"], 
                        GC_Tons: colDefaultVis["GC"], 
                        GVW_Count: colDefaultVis["GVW"], 
                        GVW_Tons: colDefaultVis["GVW"], 
                        Height_Count: colDefaultVis["Height"], 
                        Height_Tons: colDefaultVis["Height"], 
                        Manu_Count: colDefaultVis["Manu"], 
                        Manu_Tons: colDefaultVis["Manu"], 
                        MH_Count: colDefaultVis["MH"], 
                        MH_Tons: colDefaultVis["MH"], 
                        OG_Count: colDefaultVis["OG"], 
                        OG_Tons: colDefaultVis["OG"], 
                        Weight_Count: colDefaultVis["Weight"], 
                        Weight_Tons: colDefaultVis["Weight"]
                    }
                }}
                displayColumnDefOptions={{'mrt-row-select':{enableHiding:true}}}
                enableRowSelection
                positionToolbarAlertBanner="bottom"
                renderToolbarInternalActions={({ table }) => (
                    <Box>
                        <MRT_ToggleGlobalFilterButton table={table} />
                        <IconButton
                            onClick={handleClick}
                        >
                            <FileDownloadIcon />
                        </IconButton>
                        <IconButton
                            onClick={() => {
                            window.print();
                            }}
                        >
                            <PrintIcon />
                        </IconButton>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                            'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem 
                                onClick={handleExportData}
                            >
                                Export All Data
                            </MenuItem>
                            <MenuItem 
                                onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}
                                disabled={table.getPrePaginationRowModel().rows.length === 0}
                            >
                                Export All Rows
                            </MenuItem>
                            <MenuItem 
                                onClick={() => handleExportRows(table.getRowModel().rows)}
                                disabled={table.getRowModel().rows.length === 0}
                            >
                                Export Page Rows
                            </MenuItem>
                            <MenuItem 
                                onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                                disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                            >
                                Export Selected Rows
                            </MenuItem>
                        </Menu>
                        <MRT_ToggleFiltersButton table={table} />
                        <MRT_ShowHideColumnsButton table={table} />
                        <MRT_FullScreenToggleButton table={table} />
                    </Box>
                  )}
                renderTopToolbarCustomActions={({ table }) => (
                    <Box
                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                    >
                    <Button
                        color="primary"
                        // onClick={()=>{console.log("props.data",props.data.filter(e => table.getSelectedRowModel().rows.map(r=>r.original).some(f => f.ria_rte_id === e.properties.ria_rte_id && f.frm_dfo === e.properties.frm_dfo && f.to_dfo === e.properties.to_dfo)))}}
                        onClick={() => props.setLocatedRoads(table.getSelectedRowModel().rows.map(r=>r.original))}
                        // onClick={props.setMapBounds(props.getBounds(bbox(
                        //     {
                        //       type: "FeatureCollection",
                        //       features: props.data.filter(e => table.getSelectedRowModel().rows.map(r=>r.original).some(f => f.ria_rte_id === e.properties.ria_rte_id && f.frm_dfo === e.properties.frm_dfo && f.to_dfo === e.properties.to_dfo)
                        //       ) 
                        //     }
                        //   )))}
                        disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                        startIcon={<MapIcon />}
                        variant="contained"
                    >
                        Locate on map
                    </Button>
                    </Box>
                )}
            />
        )}
    </React.Fragment>
  );
};

export default AllRoadsTable;
