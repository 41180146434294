import {makeStyles} from "@mui/styles";
import Tooltip from '@mui/material/Tooltip';
import {select,scaleLinear, axisRight} from "d3";
import { useRef, useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    position:"absolute",
    // top: "0%",
    // left: "0%",
    paddingTop: "120px", 
    paddingLeft: "10px",
  },
  axis:{
    fontSize:"40px"
  }
}));

export default function ColorLegend(props) {
  const classes = useStyles();
  const svgref = useRef();

  useEffect(() => {
    const xScale = scaleLinear(props.range,[340,0]);

    const svgElement = select(svgref.current)
                       .attr('overflow','visible')
                       .attr('width',35)
                       .attr('height',350);
    const axisGenerator = axisRight(xScale).ticks(props.range[1]>500000?5:6).tickSize(8);
    svgElement.append('defs').append('linearGradient').attr('id', 'grad').attr('x1','0%').attr('x2','0%').attr('y1','0%').attr('y2','100%')
                .selectAll('stop')
                .data(['red','yellow','green'])
                .enter()
                .append('stop')
                .style('stop-color', function(d){ return d; })
                .attr('offset', function(d,i){
                    return 50 * i + '%';
                });

    svgElement.append("rect")
            .attr('height',340)
            .attr('width',65)
            .attr('fill','url(#grad)');
    svgElement.append("g")
              .style("font", "12px")
              .style("font-weight","bold")
              .call(axisGenerator);
  }, [])

  return (
     <div className={classes.container} >
        <Tooltip title="Total weight scale" placement="left">
            <svg ref={svgref}/>
        </Tooltip>
     </div>
  );
}
