import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { MainListItems, SecondaryListItems } from './listItems';
import SelectDialog from './Dialog'
import Map from './Map';
import AllRoadsTable from './AllRoadsTable';
import { RhinoQueryProvider } from "./RhinoQueryContext";
import "./App.css";
import {makeStyles} from "@mui/styles";
import PropTypes from 'prop-types';
import AppBar from "@mui/material/AppBar";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import {WebMercatorViewport} from "react-map-gl";

const drawerWidth = 250;

const AppBar1 = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  position:'fixed'
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      height: "100%",
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
      background: "#14375a"
    },
  }),
);

const mdTheme = createTheme({
  palette: {
    primary: {
      light: "#1E1E1E",
      main: "#14375a",
      dark: "#000000",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ffd35f",
      main: "#ffc838",
      dark: "#b28c27",
      contrastText: "#000",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  logo1: {
    // padding: theme.spacing(2),
    // textAlign: "left",
    // color: theme.palette.text.secondary,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  logo2: {
    //padding: theme.spacing(2),
    // textAlign: "right",
    // color: theme.palette.text.secondary,
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  title: {
    // padding: theme.spacing(2),
    // textAlign: "left",
    // color: theme.palette.text.secondary,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  linearProgress: {
    marginTop: "5px",
  },
  appBar: {
    backgroundColor: "#CC7B29",
  },
  appBarText: {
    fontSize: "1.00rem",
    color: "#FFFFFF",
  },
  mapContainer:{
    marginLeft: '14px',
    marginRight: '14px',
    paddingLeft:0,
    paddingRight: 0,
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function DashboardContent() {
  const [open, setOpen] = React.useState(false);
  const openDrawer = () => {
    setOpen(true);
  };
  const closeDrawer = () => {
    setOpen(false);
  };
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const INITIAL_VIEW_STATE = {
    longitude: -100.1803,
    latitude: 31.7953,
    zoom: 5
  };

  function getBoundsForRoads(mapBounds_bbox) {
    const cornersLongLat = [
      [mapBounds_bbox[0], mapBounds_bbox[1]],
      [mapBounds_bbox[2], mapBounds_bbox[3]],
    ];
    const viewport = new WebMercatorViewport({
      width: 800,
      height: 600,
    }).fitBounds(cornersLongLat, { padding: 100 }); // Can also use option: offset: [0, -100]
    const { longitude, latitude, zoom } = viewport;
    return { longitude, latitude, zoom };
  }

  const [show, setShow] = React.useState(false);
  const target = React.useRef(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(null);
  const [category, setCategory] = React.useState("GC");
  const [region, setRegion] = React.useState("home");
  const [layerData, setlayerData] = React.useState([]);
  const [segmentsInTable, setSegmentsInTable] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const [mapType, setMapType] = React.useState("deckGL-lines");
  const [selectedYear, setSelectedYear] = React.useState("2022");
  const [homeMapBounds, setHomeMapBounds] = React.useState(INITIAL_VIEW_STATE);
  const [locatedRoads, setLocatedRoads] = React.useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = (reg) => {
    setRegion(reg);
    setSelectedValue(null);
    setOpenDialog(!["home","roads"].includes(reg));
  };

  const handleCategory = (event) => {
    setCategory(event.target.value);
  };

  const handleClose = (value) => {
    setOpenDialog(false);
    setSelectedValue(value);
  };

  const handleChangeMapType = (event) => {
    setMapType(event.target.value);
    // setSelectedValue(null);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleSearchResult = (value) =>{
    setRegion(value.category);
    setSelectedValue(value);
  };

  const classes2 = useStyles();
  
  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex',height: '100vh' }}>
        <CssBaseline />
        <AppBar1 position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            {/* <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              FPIAC TxDOT Oversize/Overweight
            </Typography> */}
            <Grid container spacing={3} align="center" justify="center">
              <Grid item xs={2} className={classes2.logo1}>
                <img
                  className="logo"
                  src={
                    "https://tcatwebprod.z14.web.core.windows.net/Transparent%20Background%20Color3lineLOGO_TxDOT_WHITE_300dpi.png"
                  }
                  alt="TxDOT Logo"
                  title="TxDOT Logo"
                  style={{
                    width: '35%',
                    height: '6vh',
                  }}
                />
              </Grid>
              {/* <Grid item xs={8} className={classes2.title}> */}
              <Grid item xs={8} container alignItems="center" justifyContent="center" >
                {/* <Typography variant="h4" noWrap style={{ paddingTop: "7.5px" }}> */}
                <Typography variant="h4" noWrap >
                FPIAC TxDOT Oversize/Overweight
                </Typography>
              </Grid>
              <Grid item xs={2} className={classes2.logo2}>
                <img
                  className="logo2"
                  src={"https://tcatwebprod.z14.web.core.windows.net/TTI-Color-reverse.png"}
                  alt="TTI Logo"
                  title="TTI Logo"
                  style={{
                    height: '5vh',
                    width: '67%',
                  }}
                />
              </Grid>
            </Grid>
            {/* <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
          </Toolbar>
        </AppBar1>
        <Drawer variant="permanent" open={open} onMouseOver={openDrawer} onMouseLeave={closeDrawer}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
              backgroundColor:'#14375a',
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon sx={{color: "#FFFFFF  !important"}}/>
            </IconButton>
          </Toolbar>
          <Divider />
          <List>
            <MainListItems
              openAction={handleClickOpen}
              drawerToggle={toggleDrawer}
              setSearchResult={handleSearchResult}
            />
          </List>
          <Divider />
          {/* <List>{SecondaryListItems()}</List> */}
          <SelectDialog
            selectedValue={selectedValue}
            region={region}
            open={openDialog}
            onClose={handleClose}
          />
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            minHeight: '100%',
            // overflow: 'auto'
          }}
        >
          <Toolbar />
          <div className={classes2.mapContainer}>
            <Grid container spacing={1}>
            <RhinoQueryProvider>
              {/* Layer Control */}
              {/* <Grid item xs={12} md={4} lg={3}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 640,
                  }}
                >
                  <LayerControl />
                </Paper>
              </Grid> */}

              {/* Map */}
              <Grid item xs={12} md={12} lg={12}>
                <div style= {{ display: 'flex', justifyContent: 'space-between'}}>
                  <div>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel id="demo-select-small">Year</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={selectedYear}
                      label="Year"
                      onChange={handleYearChange}
                    >
                      <MenuItem value={2015}>2015</MenuItem>
                      <MenuItem value={2016}>2016</MenuItem>
                      <MenuItem value={2017}>2017</MenuItem>
                      <MenuItem value={2018}>2018</MenuItem>
                      <MenuItem value={2019}>2019</MenuItem>
                      <MenuItem value={2020}>2020</MenuItem>
                      <MenuItem value={2021}>2021</MenuItem>
                      <MenuItem value={2022}>2022</MenuItem>
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel id="demo-select-small">Category</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={category}
                      label="Category"
                      onChange={handleCategory}
                    >
                      <MenuItem value={'GC'}>General Construction</MenuItem>
                      <MenuItem value={'GVW'}>Gross Vehicle Weight</MenuItem>
                      <MenuItem value={'Height'}>Over 16ft in height</MenuItem>
                      <MenuItem value={'Manu'}>Manufacturing</MenuItem>
                      <MenuItem value={'MH'}>Manufacturing Housing</MenuItem>
                      <MenuItem value={'OG'}>Oil and Gas</MenuItem>
                      <MenuItem value={'Weight'}>Over 250Klb GVW</MenuItem>
                  </Select>
                </FormControl>
                </div>
                {selectedValue && <h3 sx={{justifyContent:'center'}}>{selectedValue.statusGuide.join(" | ")}</h3>}
                <RadioGroup aria-label="gender" name="controlled-radio-buttons-group-map-type" value={mapType} onChange={handleChangeMapType} row sx={{justifyContent:'end'}}>
                  <FormControlLabel value={"deckGL-lines"} control={<Radio />} label="Roadways as Lines" />
                  <FormControlLabel value={"deckGL-polygons"} control={<Radio />} label="Roads as Polygons" />
                </RadioGroup>
                </div>
                <Paper
                  sx={{
                    p: 2,
                    position:'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    height: 640,
                  }}
                >
                  <Map 
                    category={category}
                    region={region}
                    selectedValue={selectedValue}
                    setSelectedValue = {setSelectedValue}
                    data={layerData}
                    setData={setlayerData}
                    inTable={segmentsInTable}
                    actionTable={setSegmentsInTable}
                    mapType={mapType}
                    setMapType={setMapType}
                    selectedYear={selectedYear}
                    initialMapView={INITIAL_VIEW_STATE}
                    mapBounds={homeMapBounds}
                    setMapBounds={setHomeMapBounds}
                    getBounds={getBoundsForRoads}
                    locatedRoads={locatedRoads}
                    setLocatedRoads={setLocatedRoads}
                  />
                </Paper>
              </Grid>
              
              {/* Table */}
              <Grid item xs={12}>
                <Paper sx={{ display: 'flex', flexDirection: 'column' }}>
                  <AppBar color="secondary" position="static" className={classes2.appBar}>
                    <AllRoadsTable
                      partialView={false}
                      category={category}
                      data={layerData}
                      setMapBounds={setHomeMapBounds}
                      getBounds={getBoundsForRoads}
                      setLocatedRoads={setLocatedRoads}
                    />
                  </AppBar>
                </Paper>
              </Grid>
            </RhinoQueryProvider>
            </Grid>
          </div>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}